
.coleta{
    width: 80%;
    max-width: 330px;
    padding: 5px;
    margin: 5px;
    border: 1px solid black;
    position: relative;
}
.coleta p{
    display: block;
    margin: 5px;
}
