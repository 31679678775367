
.header{
    width: 100vw;
    max-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #025a1cd2;
    padding: 10px 0px;
}
.titleContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.title{
    font-family: Arial, Helvetica, sans-serif;
    color: #eafff0;
    text-transform: uppercase;
    font-size: .9rem;
    font-weight: bolder;
    letter-spacing: .1em;
    text-shadow: 0px 0px 10px #012340;
    transform: scaleY(2.5);
    margin-top: 10px;
}
.logo{
    background-image: url("../../assets/imgs/icos/plantinha.png");
    background-size: contain;
    width: 50px;
    height: 50px;
    margin-left: 10px;
}

@media screen and (min-width: 300px){
    .header{
        padding: 20px 0px;
    }
    .title{
        font-size: 1.3rem;
        margin-top: 15px;
    }
    .logo{
        margin-left: 20px;
        width: 70px;
        height: 70px;
    }
}

@media screen and (min-width: 500px){
    .header{
        padding: 20px 0px;
    }
    .title{
        font-size: 1.6rem;
        margin-top: 20px;
    }
    .logo{
        margin-left: 30px;
        width: 80px;
        height: 80px;
    }
}
