
.loading{
    width: 0;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #025a1cd2;
    display: flex;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-weight: bold;
    letter-spacing: .1rem;
}
.loading.true{
    width: 100vw;
}

@media screen and (min-width: 300px){
    .loading{
        font-size: 1.2rem;
    }
}

@media screen and (min-width: 500px){
    .loading{
        font-size: 1.3rem;
    }
}
