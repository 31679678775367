
.confirmacao{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #03A63C;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.confirmacao.visible{
    display: flex;
}
.confirmacao > div{
    display: flex;
    justify-content: center;
}
.confirmacao p{
    margin-bottom: 10px;
    text-align: center;
    font-size: .8rem;
}

@media screen and (min-width: 300px){
    .confirmacao p{
        font-size: 1rem;
    }
}
