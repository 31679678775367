
.footer{
    color: #ffffff;
    width: 100%;
    height: auto;
    text-align: center;
    text-shadow: 0 0 10px #012340; 
    margin-bottom: 20px;
    margin-top: 20px;
}
