
.relatorio{
    background-color: #ffffffe1;
    height: auto;
    width: 100%;
    padding: 5px 0px 15px 0px;
    margin-bottom: 40px;
    justify-content: center;
}
.containerTopic{
    margin: 10px 0px 10px 10px;
}
.containerTopic h1{
    font-size: 1.1em;
    margin: 0px;
    margin-bottom: 5px;
    color: #025939;
    text-shadow: 0px 0px 1px #000000b2 ;
}
.containerTopic h2, .containerTopic p {
    display: inline;
    font-size: 1em;
    margin: 5px 0px 0px 10px
}

@media screen and (min-width: 300px){
    .containerTopic{
        margin: 15px 0px 10px 20px;
    }
    .containerTopic h1{
        font-size: 1.3em;
    }
    .containerTopic h2, .containerTopic p {
        font-size: 1.1em;
    }
}

@media screen and (min-width: 500px){
    .relatorio{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .containerTopic{
        margin: 20px 40px 10px 40px;
    }
    .containerTopic h1{
        font-size: 1.5em;
    }
    .containerTopic h2, .containerTopic p {
        font-size: 1.2em;
    }
}