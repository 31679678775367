
.formNewColeta{
    background-color: #ffffffe1;
    height: auto;
    width: 100%;
    padding: 10px 0px;
    margin-bottom: 40px;
    justify-content: center;
}
.formNewColeta p{
    display: block;
    width: 150px;
    margin: 0px auto 10px auto;
    font-size: .9rem;
}
.erro{
    color: #ff0000;
}
.sucesso{
    color: #008000;
}
.formNewColeta button{
    display: block;
    width: 100px;
    margin: auto;
    margin-top: 10px;
}

@media screen and (min-width: 300px){
    .formNewColeta{
        padding: 20px 0px;
    }
    .formNewColeta p{
        width: 250px;
        margin-bottom: 15px;
        font-size: 1rem;
        text-align: center;
    }
    .formNewColeta button{
        width: 150px;
        font-size: 1.1rem;
    }
}

@media screen and (min-width: 500px){
    .formNewColeta{
        padding: 30px 0px;
    }
    .formNewColeta p{
        width: 450px;
        margin-bottom: 25px;
        font-size: 1.1rem;
    }
    .formNewColeta button{
        width: 200px;
        font-size: 1.3rem;
        padding: 5px;
    }
}
