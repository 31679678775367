
.App{
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background-image: url(../assets/imgs/background.jpg);
  background-size: cover ;
  overflow-x: hidden;
  overflow-y: scroll;
}
