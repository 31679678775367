
.container{
    background-color: #ffffffe1;
    height: auto;
    width: 100%;
    padding: 10px 0px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.alert{
    padding: 10px;
    text-align: center;
}

@media screen and (min-width: 300px){
    .alert{
        padding: 20px;
        text-align: center;
    }
}

@media screen and (min-width: 500px){
    .alert{
        padding: 30px;
    }
}
