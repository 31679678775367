
.menu{
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

@media screen and (min-width: 300px){
    .menu{
        margin-top: 15px;
    }
}

@media screen and (min-width: 500px){
    .menu{
        margin-top: 20px;
        flex-direction: row;
    }
}
